<template>
  <wiskInputGroup @errorCountChanged="setValidState" :disabled="disabled" style="min-height: 580px;" @operationsChange="onChange" v-if="localVenue" ref="inputGroup">

    <div v-if="!localVenue.id" style="min-height: 600px;">
      <div class="mt-3 mb-4 d-block" style="height: 40px;" v-if="hasOtherVenues">
        <infoTooltip inline :scale="1.2" helpKey="newVenueCreationInfoForExistingUser" />
      </div>

      <venueFinder :venue="localVenue" @change="venueFinderChanged" autofocus :preloadTitle="preloadTitle" />

      <wiskSelect infoTooltipKey="4e85f2b4-586a-4782-a98c-b663d9a930bd" :label="translations.txtVenueOperationsVenueType" :modelValue="localVenue.venue_type" required operation="venue_type"
        :items="venueTypes" :multiselectOptions="{}" @operation="onSingleChange" displayAsButtonGroup />

      <wiskSelect infoTooltipKey="c57f2fd2-4fa7-46ff-8264-f24e705a1989" :label="translations.txtVenueOperationsPopulateData" v-model="populateMethod"
        :items="newVenueDataPopulateMethods" displayAsButtonGroup />

      <template v-if="populateMethod === 2">
        <wiskInput v-if="godMode && currentVenue?.id" infoTooltipKey="45689096-e3b5-4668-8f6a-64e8acdb8ea9" :label="translations.translate('tplVenueOperationsCentralItemsList', { '{a}': currentVenue.title })"
          operation="use_parent_items" class="" @operation="onSingleChange" v-model="localVenue.use_parent_items" @update:modelValue="onUseParentItemsChange" inputType="checkbox" disabled triggerInputOnLoad>

          <wiskInput infoTooltipKey="29eda0ea-8c61-4cb5-94e6-afb85f2fedeb" v-if="godMode && localVenue.use_parent_items" :label="translations.translate('tplVenueOperationsCentralPOSItemsList', { '{a}': currentVenue.title })"
            operation="use_parent_pos_items" class="" @operation="onSingleChange" v-model="localVenue.use_parent_pos_items" inputType="checkbox" />
        </wiskInput>

      </template>

      <template v-if="populateMethod === 1">
        <wiskSelect infoTooltipKey="89f8dc06-1b76-416b-8aa7-de1c80e07a9e" operation="copy_data_from_venue" name="copyData"
          :items="copyDataOptions" displayAsButtonGroup :buttons="false" v-model="localVenue.copy_data_from_venue" />
      </template>

      <fieldset v-if="godMode" class="mt-5" style="position: relative; padding-left: 31px; padding-right: 31px; width: 100%;">
        <legend class="mb-3" style="">
          {{ translations.txtVenueOperationsAdminFields }}
        </legend>

        <b-row>
          <b-col class="venue-settings-col" cols="12" md="8">
            <paymentInfo :venue="localVenue" @change="paymentInfoChanged" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="a615780b-7c93-4e6e-aa2c-ef4f4ca51e66" :label="translations.txtVenuesStripeId" operation="stripe_id" operationEmpty="stripe_id_clear"
              :modelValue="localVenue.stripe_id" :fitChildren="!!localVenue.stripe_id">
              <a v-if="localVenue.stripe_id" ref="noopener" :href="`https://dashboard.stripe.com/customers/${localVenue.stripe_id}`" target="_blank"> {{ translations.txtVenuesGoToStripe }} </a>
            </wiskInput>
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="82299dbd-af65-40b3-acc5-55581616ed81" :label="translations.txtVenueOperationsTesting" :modelValue="localVenue.testing" inputType="checkbox"
              operation="testing" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskSelect infoTooltipKey="e6545aa2-2103-4ebd-b84b-d43fb63bdc41" label="Invoice scanning type" :modelValue="localVenue.draft_invoices_settings.type"
              :items="draftInvoiceSettingTypes" :multiselectOptions="{}" @update:modelValue="onDraftInvoiceSettingChange" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskSelect infoTooltipKey="53ab48b8-9970-4976-ad14-c6473a73311f" label="Organization" :modelValue="localVenue.organization_id"
              :items="organizations" operation="organization_id" :addNewItem="addNewOrganizationMultiselectAction" :loading="loadingOrganizations" />
          </b-col>
        </b-row>
      </fieldset>

    </div>
    <div v-else class="pb-2">
      <b-row class="mb-2">
        <b-col class="venue-settings-col" cols="12" md="3">
          <div class="position-relative images-controls-wrapper">
            <imageView :src="localVenue.white_label" filterDisplay="smallLandscape" @click="editVenueImage" class="mb-3" style="max-width: 200px;" />
            <div class="position-absolute" v-if="localVenue.white_label" style="top: 0; right: 0;">
              <b-button variant="link" @click="removeVenueImage" class="bt-delete-image p-1" v-tooltip="translations.txtGenericRemove">
                <icon name="wisk-trash" class="image-view-overlay-icon text-danger" :scale="1" />
              </b-button>
            </div>
            <div class="position-absolute" style="bottom: 0; right: 0;">
              <b-button variant="link" @click="editVenueImage" class="bt-delete-image p-1" v-tooltip="translations.txtGenericEdit">
                <icon name="wisk-edit" class="image-view-overlay-icon text-primary" :scale="1" />
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="8">
          <venueFinder :venue="localVenue" @change="venueFinderChanged" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskSelect infoTooltipKey="eecab665-2d13-4984-9a75-dce6c791b876" :label="(translations.txtGenericLanguage === 'Language' && translations.txtGenericLanguage) || translations.txtGenericLanguage + ' / Language'"
            :modelValue="localVenue.language" operation="language" :items="languages" :multiselectOptions="{}" @operation="onSingleChange" required />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskSelect infoTooltipKey="5639bca2-6b5d-455e-95ba-435203086ae3" :label="translations.txtGenericDisplayCurrency" :modelValue="selectedCurrency" required operation="display_currency"
            :items="currencies" @operation="onSingleChange" trackBy="code" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="39d5ac77-82c2-421d-a4f5-443c7bac582c" :label="translations.txtVenueOperationsBusinessDayStartHour" :modelValue="`${venue.business_day_starting_hour}:00`" inputType="timePicker"
            operation="business_day_starting_hour" class="" @operation="onSingleChange({ ...$event, value: parseInt($event.value, 10), from: venue.business_day_starting_hour })" :timePickerOptions="{ minutesDisabled: true }" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskSelect infoTooltipKey="40b3a172-1fba-4b75-92c9-fe7983b08fea" :label="translations.txtVenueOperationsPartialMeasuringType" :modelValue="localVenue.partial_measuring_type" operation="partial_measuring_type"
            :items="partialMeasuringTypes" :multiselectOptions="{}" @operation="onSingleChange" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="5a0305ac-27ea-49bf-97c0-9d3b956b3f92" :label="translations.txtVenueOperationsIdealStockDays" :modelValue="localVenue.ideal_stock_days" inputType="number"
            operation="ideal_stock_days" :decimals="1" decimalsAsNeeded @operation="onSingleChange" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="08e40eeb-ca65-4ed5-830f-157b40013b40" :label="translations.txtVenueOperationsInventoryHours" :modelValue="localVenue.inventory_hours_before_wisk" inputType="number"
            operation="inventory_hours_before_wisk" :decimals="1" decimalsAsNeeded @operation="onSingleChange" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="9c3bc5fe-47ce-4721-8bb8-2f81d077a84e" :label="translations.txtVenueOperationsInventoryLockDaysAfterApproving" :modelValue="localVenue.days_to_lock_inventory_after_approving" inputType="number"
            operation="days_to_lock_inventory_after_approving" @operation="onSingleChange" :min="1" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="c1ae4f2e-fc15-44f1-a11c-81fb628484e7" :label="translations.txtVenueOperationsInventoryLockDaysAfterUnlocking" :modelValue="localVenue.days_to_lock_inventory_after_unlocking" inputType="number"
            operation="days_to_lock_inventory_after_unlocking" @operation="onSingleChange" :min="1" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="d5397e14-c28f-45e5-9289-8e8175b0f75b" :label="translations.txtVenueOperationsRealtimeStockRecalculationDays" :modelValue="localVenue.real_time_stock_threshold_days" inputType="number"
            operation="real_time_stock_threshold_days" @operation="onSingleChange" :min="1" :max="365" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="eb225c3f-7489-46cd-a46b-3048fe3bb1c0" :label="translations.txtVenueOperationsTransfers" :modelValue="localVenue.transfers" inputType="checkbox"
            operation="transfers" class="float-start me-3 mb-2" @operation="onSingleChange" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="33ed65ed-77d5-4c61-be19-b3ac79276d20" :label="translations.txtVenueOperationsPriceRetail" :modelValue="localVenue.price_retail" inputType="checkbox"
            operation="price_retail" class="float-start me-3 mb-2" @operation="onSingleChange" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3" v-if="priceFeeAvailable">
          <wiskInput infoTooltipKey="2c7ab526-3d34-442c-943c-5361a2598e4b" v-if="priceFeeAvailable" :label="translations.txtVenueOperationsPriceFee" :modelValue="localVenue.price_fee" inputType="checkbox"
            operation="price_fee" class="float-start me-3 mb-2" @operation="onSingleChange" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="6c9c8794-7893-4abf-9922-0c3ad908a327" :label="translations.txtGenericScale" :modelValue="localVenue.scale" inputType="checkbox"
            operation="scale" class="float-start me-3 mb-2" @operation="onSingleChange" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="4f378036-f389-489c-9210-55d5f816906d" :label="translations.txtVenueOperationsWeightedAveragePricing" :modelValue="localVenue.weighted_average_pricing" inputType="checkbox"
            operation="weighted_average_pricing" class="float-start me-3 mb-2" @operation="onSingleChange" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="e9f1fca5-bea1-4a6d-8579-08b0efa02f90" :label="translations.txtVenueOperationsRequireIntakePhoto" :modelValue="localVenue.require_intake_photos" inputType="checkbox"
            operation="require_intake_photos" class="float-start me-3 mb-2" @operation="onSingleChange" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3">
          <wiskInput infoTooltipKey="e482eea1-63b1-492e-8edb-2c1ec3005512" :label="translations.txtVenueOperationsLiveSales" :modelValue="localVenue.live_sales" inputType="checkbox"
            operation="live_sales" class="float-start me-3 mb-2" @operation="onSingleChange" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="6">
          <barcodePrinterSetup inline />
        </b-col>
        <b-col v-if="measurementsByType" class="venue-settings-col" cols="12" md="3">
          <wiskSelect infoTooltipKey="cbc8f0ad-1d13-43e7-8667-029d06fe9503" :label="translations.txtVenueOperationsDefaultUMVolume" :modelValue="localVenue.default_um_volume"
            :items="measurementsVolume" operation="default_um_volume" @operation="onSingleChange" />
        </b-col>
        <b-col v-if="measurementsByType" class="venue-settings-col" cols="12" md="3">
          <wiskSelect infoTooltipKey="3d4cc885-15a5-4aa6-a527-2ca10f2e8262" :label="translations.txtVenueOperationsDefaultUMWeight" :modelValue="localVenue.default_um_weight"
            :items="measurementsWeight" operation="default_um_weight" @operation="onSingleChange" />
        </b-col>
        <b-col class="venue-settings-col" cols="12" md="3" v-if="localVenue.custom_attributes && localVenue.custom_attributes.api_key">
          <wiskInput infoTooltipKey="23b82ec4-0d5e-452b-a780-99e0ec966298" :label="translations.txtGenericApiKey" :modelValue="localVenue.custom_attributes.api_key" readonly inputType="text"
            v-if="localVenue.custom_attributes && localVenue.custom_attributes.api_key" />
        </b-col>
        <b-col v-if="showChangeSubscriptionPlatform" class="venue-settings-col" cols="12" md="3">
          <b-button @click="onSingleChange({ type: 'migrate_to_stripe_subscription' })" variant="primary" size="sm" class="mb-4">
            {{ translations.txtVenueOperationsChangeSubscriptionPlatform }}
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="venue-settings-col" cols="12" md="6" v-if="godMode || user?.admin">
          <wiskInput inputType="text" :passwordField="!showApiKey" readonly :label="translations.txtGenericVenueApiKey" :modelValue="venue.api_key" fitChildren :fitChildrenCols="1">
            <b-copy v-if="showApiKey" variant="light" class="ms-1" :copyText="venue.api_key" />
            <b-button v-else size="sm" variant="light" class="ms-1" @click="showApiKey = true">
              <icon style="margin-top: 2px;" class="text-primary" name="wisk-show" :scale="1" />
            </b-button>
          </wiskInput>
        </b-col>

        <b-col class="venue-settings-col" cols="12" md="6">
          <b-button variant="primary" size="sm" @click="resetLocalData">
            {{ translations.txtVenueOperationsResetLocalVenueData }}
          </b-button>
        </b-col>
      </b-row>

      <venuePOSIntegrations @operation="onSingleChange" />

      <fieldset v-if="localVenue.accounting_enabled && localVenue.accounting_integration" class="" style="position: relative; padding-left: 31px; padding-right: 31px;">
        <legend class="mb-3" style="">
          {{ translations.txtVenueOperationsAccountingIntegration }}
        </legend>

        <b-row>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskSelect infoTooltipKey="503b90c1-4bed-4b47-8399-ea5757998f4b" :label="translations.txtGenericType" :modelValue="localVenue.accounting_integration.type" operation="accounting_integration"
              :items="accountingIntegrationTypes" :multiselectOptions="{}" @operation="onSingleChange({ ...$event, value: { type: $event.value, status: $event.value === 'quickbooks' ? 'not_set_up' : 'active' } })" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3" v-if="venue?.accounting_integration?.is_using_classes && localVenue.accounting_integration?.type === 'quickbooks'">
            <wiskSelect infoTooltipKey="8e35e063-0edc-4b77-b40c-60f1265e69e3" label="Default Quickbooks class" :modelValue="localVenue?.accounting_integration?.default_venue_class_id"
              :items="accountingVenueClasses" :multiselectOptions="{}" operation="default_accounting_venue_class_id" operationEmpty="default_accounting_venue_class_id_clear" @operation="onSingleChange" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="6" v-if="localVenue.accounting_enabled && localVenue.accounting_integration?.type === 'quickbooks'">
            <b-button variant="primary" size="sm" @click="goToQuickbooks" preventDoubleClick>
              {{ translations.txtVenueOperationsAccountingSetup }}
            </b-button>
            <span class="ms-3" :class="{ 'text-danger': !accountingIntegrated, 'text-success': accountingIntegrated }">
              {{ accountingIntegrated ? translations.txtVenueOperationsAccountingSetupDone : translations.txtVenueOperationsAccountingSetupNone }},
            </span>
            <span class="ms-1 text-bold" :class="{ 'text-danger': localVenue.accounting_integration.status === 'expired', 'text-success': localVenue.accounting_integration.status === 'active' }">
              {{ localVenue.accounting_integration.status }}
            </span>
          </b-col>
        </b-row>
      </fieldset>

      <fieldset v-if="godMode" class="" style="position: relative; padding-left: 31px; padding-right: 31px;">

        <legend class="mb-3" style="">
          {{ translations.txtVenueOperationsAdminFields }}
        </legend>

        <b-row>
          <b-col class="venue-settings-col" cols="12" md="6">
            <paymentInfo :venue="localVenue" @change="paymentInfoChanged" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="a615780b-7c93-4e6e-aa2c-ef4f4ca51e66" :label="translations.txtVenuesStripeId" operation="stripe_id" operationEmpty="stripe_id_clear"
              :modelValue="localVenue.stripe_id" @operation="onSingleChange" :fitChildren="!!localVenue.stripe_id">
              <a v-if="localVenue.stripe_id" ref="noopener" :href="`https://dashboard.stripe.com/customers/${localVenue.stripe_id}`" target="_blank"> {{ translations.txtVenuesGoToStripe }} </a>
            </wiskInput>
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskSelect infoTooltipKey="53ab48b8-9970-4976-ad14-c6473a73311f" label="Organization" :modelValue="localVenue.organization_id" :loading="loadingOrganizations"
              :items="organizations" operation="organization_id" @operation="onSingleChange" :addNewItem="addNewOrganizationMultiselectAction" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskSelect infoTooltipKey="b18cc2d4-275c-4c7d-a642-ec8098eb696d" :label="translations.txtVenueOperationsVenueType" :modelValue="localVenue.venue_type" required operation="venue_type"
              :items="venueTypes" @operation="onSingleChange" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskSelect infoTooltipKey="37de3a0b-49f4-4505-87b5-2c92740f165b" :label="translations.txtVenueOperationsSalesRep" :modelValue="localVenue.sales_rep" operation="sales_rep"
              :items="salesReps" :multiselectOptions="{}" @operation="onSingleChange" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="82299dbd-af65-40b3-acc5-55581616ed81" :label="translations.txtVenueOperationsTesting" :modelValue="localVenue.testing" inputType="checkbox"
              operation="testing" @operation="onSingleChange" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="19b4b93d-a5d4-4a04-bdc6-4f3ad260f7dc" :label="translations.txtVenueTransferRequestEnabled" :modelValue="localVenue.multivenue_enabled" inputType="checkbox"
              operation="multivenue_enabled" class="float-start me-3 mb-2" @operation="onSingleChange" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskSelect infoTooltipKey="e6545aa2-2103-4ebd-b84b-d43fb63bdc41" label="Invoice scanning type" :modelValue="localVenue.draft_invoices_settings.type"
              :items="draftInvoiceSettingTypes" :multiselectOptions="{}" @update:modelValue="onDraftInvoiceSettingChange" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="895a1db9-0860-434d-b66f-63fdd52550da" label="Draft Invoices - Final Approval Needed" :modelValue="localVenue.draft_invoice_final_approval_needed"
              inputType="checkbox" operation="draft_invoice_final_approval_needed" class="me-3 mb-2" @operation="onSingleChange" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="668e5f65-462e-4151-9de8-d53b21b659e9" label="Accounting Integration" :modelValue="localVenue.accounting_enabled" inputType="checkbox"
              operation="accounting_enabled" @operation="onSingleChange" class="mb-0" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="8d0ca9f6-703d-4df0-8a24-1d32fccd50d0" label="Taxes" :modelValue="localVenue.taxes_enabled" inputType="checkbox"
              operation="taxes_enabled" @operation="onSingleChange" class="mb-0" />
          </b-col>
          <!-- <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="94bf03f7-b2b2-41df-8610-09bdce87125b" label="Distributor Venue" :modelValue="localVenue.venue_as_distributor" inputType="checkbox"
              operation="venue_as_distributor" class="float-start me-3 mb-2" @operation="onSingleChange" />
          </b-col> -->
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="b0b6595f-e958-4d3f-9156-dcf79b2eac81" :label="translations.txtVenueOperationsFintechLabel" operation="fintech_label"
              :modelValue="localVenue.fintech_label" @operation="onSingleChange" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="12b05e38-d600-4efc-8e64-19b17766d488" :label="translations.txtVenueOperationsSalesConversation" :modelValue="localVenue.support_sales_conversion"
              operation="support_sales_conversion" @operation="onSingleChange" inputType="checkbox" />
          </b-col>
          <b-col class="venue-settings-col" cols="12" md="3">
            <wiskInput infoTooltipKey="4eb9f1b6-b0e7-4a79-b19d-2ce9b21b96ee" :label="translations.txtVenueOperationsShowCurrentDistributorPrices" :modelValue="localVenue.show_current_distributor_prices"
              operation="show_current_distributor_prices" @operation="onSingleChange" inputType="checkbox" />
          </b-col>
          <b-col v-if="localVenue?.draft_invoices_settings?.type === 'wisk_with_items' || localVenue?.draft_invoices_settings?.type === 'wisk_without_items'" cols="12" md="6">
            <wiskInput inputClass="mt-2" infoTooltipKey="06c5720f-fa52-45dd-a1c4-62b2701b3c72" :label="translations.txtVenueOperationsInvoiceProcessingNotes" operation="internal_notes"
            :modelValue="localVenue.internal_notes" multiline autoHeightTextArea @operation="onSingleChange" />
          </b-col>
          <b-col class="venue-settings-col" style="text-align: right;" cols="12">
            <b-button variant="outline-primary" size="sm" @click="sendVenueReport('weekly')" class="ms-2">
              Send weekly report
            </b-button>

            <b-button variant="outline-primary" size="sm" @click="sendVenueReport('daily')" class="ms-2">
              Send daily report
            </b-button>

            <b-button variant="outline-primary" size="sm" @click="loadCleanupPOSItemsPreview" class="ms-2">
              Remove unused POS items
            </b-button>

            <b-button variant="outline-primary" size="sm" @click="resyncVenueData" class="ms-2">
              {{ translations.txtVenueOperationsResyncVenueData }}
            </b-button>

            <b-button variant="outline-primary" size="sm" @click="resyncAreasData" class="ms-2">
              {{ translations.txtVenueOperationsResyncAreasData }}
            </b-button>
          </b-col>
        </b-row>
      </fieldset>

      <customFields target="venue" :item="localVenue" @operation="onSingleChange" />

    </div>
    <confirm ref="confirmDialog" />
    <confirm ref="confirmDialogResyncVenueData">
      <wiskInput inputType="checkbox" v-model="resyncVenueDataIncreaseVersion" label="Force full data download for all users of the venue" infoTooltipKey="resyncVenueDataIncreaseVersionTooltip" />
    </confirm>
  </wiskInputGroup>

  <wiskModal title="Remove unused POS items" :visible="posItemsCleanupDialog" @hide="posItemsCleanupDialog = false" @ok="confirmCleanupPOSItems" size="lg">
    <posItemsGrid :loading="cleanupDialogLoading" :showFoundArchived="true" :items="posItemsCleanupPreview" :gridStyle="{ height: `calc(100vh - 150px)` }" disableSelection />
  </wiskModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import merge from 'lodash.merge'
import get from 'lodash.get'
import api from '@/api'
import paymentInfo from '@/components/venues/VenuePaymentInfo'
import barcodePrinterSetup from '@/components/venues/BarcodePrinterSetup'
import venueFinder from '@/components/venues/VenueFinder'
import infoTooltip from '@/components/common/WiskInfoTooltip'
import customFields from '@/components/customFields/CustomFieldsRender'
import imageView from '@/components/common/ImageView'
import posItemsGrid from '@/components/posItems/POSItemsGrid.vue'
import venuePOSIntegrations from '@/components/venues/VenuePOSIntegrations'

export default {
  name: 'VenueOperations',
  emits: ['validStateChanged', 'refresh', 'singleOperation', 'input'],
  components: { venuePOSIntegrations, posItemsGrid, venueFinder, paymentInfo, infoTooltip, customFields, imageView, barcodePrinterSetup },
  props: {
    disabled: Boolean,
    admin: Boolean,
    venue: Object,
    preloadTitle: String,
    currentVenue: Object
  },
  data() {
    return {
      localVenue: { use_parent_items: false },
      syncProviders: [
        { id: 'firestore', title: 'firestore' },
        { id: 'couchbase', title: 'couchbase' },
        { id: 'wisk-sync', title: 'wisk-sync' },
        { id: 'hybrid', title: 'hybrid' },
      ],
      accountingIntegrationTypes: [
        { id: 'quickbooks', title: 'QuickBooks Online' },
        { id: 'quickbooks_desktop', title: 'QuickBooks Desktop' }
      ],
      draftInvoiceSettingTypes: [
        { id: 'none', title: 'None' },
        { id: 'venue', title: 'Venue' },
        { id: 'wisk_with_items', title: 'Wisk with items' },
        { id: 'wisk_without_items', title: 'Wisk without items' },
      ],
      operations: [],
      localOperations: [],
      venueFindOperations: [],
      posComponentKey: 1,
      posComponentKeySecondary: 1,
      paymentOperations: [],
      valid: true,
      initDone: false,
      resyncVenueDataIncreaseVersion: false,
      showApiKey: false,
      populateMethod: 0,
      organizations: [],
      loadingOrganizations: false,
      posItemsCleanupDialog: false,
      posItemsCleanupPreview: [],
      cleanupDialogLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'timeZones',
      'venueTypes',
      'onboardingTypes',
      'statuses',
      'partialMeasuringTypes',
      'currencies',
      'salesReps',
      'measurementsByType'
    ]),
    ...mapState(['posItemsById', 'customFieldsByTarget', 'user', 'translations', 'currentPermissionsByType', 'accountingVenueClasses']),
    newVenueDataPopulateMethods() {
      return [
        { id: 0, title: this.translations.txtVenueOperationsPopulateDataNew },
        { id: 1, title: this.translations.txtVenueOperationsPopulateDataCopy },
        { id: 2, title: this.translations.txtVenueOperationsPopulateDataShare }
      ]
    },
    measurementsVolume() {
      return this.measurementsByType.volume?.units_of_measurements.filter(m => ![ 'cup', 'tbsp', 'tsp'].includes(m.id))
    },
    measurementsWeight() {
      return this.measurementsByType.weight?.units_of_measurements
    },
    addNewOrganizationMultiselectAction() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'organizationEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: organization => {
                callbackItemInjected(organization)
              }
            }
          })
        },
        label: this.translations.txtOrganizationsNew
      }
    },
    venuesForPOSIngredientsCopy() {
      return this.user.user_venues.filter(v => v.id !== this.currentVenue.id).map(uv => uv.venue)
    },
    hasOtherVenues() {
      return !!this.user.user_venues.length
    },
    showChangeSubscriptionPlatform() {
      return get(this.localVenue, 'subscription.selected_plan.payment_platform') === 'apple'
    },
    godMode() {
      return !!this.user?.god_mode
    },
    accountingIntegrated() {
      return this.venue?.accounting_integration?.status === 'active'
    },
    selectedCurrency() {
      if (this.currencies && this.currencies.length && this.localVenue && this.localVenue.display_currency) {
        return this.currencies.find(c => c.code === this.localVenue.display_currency.code)
      }
      return null
    },
    priceFeeAvailable() {
      if (this.venue && this.venue.country === 'CA' && this.venue.state === 'QC') {
        return true
      }
      return false
    },
    copyDataOptions() {
      return [
        { title: this.translations.translate('tplVenueOperationsCopyAllList', { '{a}': this.currentVenue.title }), id: 'all' },
        { title: this.translations.translate('tplVenueOperationsCopyBatchesList', { '{a}': this.currentVenue.title }), id: 'batches' },
        { title: this.translations.translate('tplVenueOperationsCopyItemsList', { '{a}': this.currentVenue.title }), id: 'items' }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'resetSyncProviderLocalPersistence', 'notify']),
    loadCleanupPOSItemsPreview() {
      this.posItemsCleanupDialog = true
      this.cleanupDialogLoading = true
      this.posItemsCleanupPreview = []
      api.cleanUnusedPOSItemsPreview().then(response => {
        const temp = []
        response.pos_item_ids.forEach(p => {
          temp.push(this.posItemsById[p])
        })
        this.posItemsCleanupPreview = temp
        this.cleanupDialogLoading = false
      })
    },
    confirmCleanupPOSItems() {
      this.posItemsCleanupDialog = false
      api.cleanUnusedPOSItemsConfirm()
    },
    posRetry() {
      api.posRetry(this.venue.id).then(() => {
        this.notify({
          message: 'POS retry started.',
          type: 'info'
        })
      })
    },
    refreshVenue() {
      this.$emit('refresh')
    },
    setValidState(errorCount) {
      this.valid = !errorCount
      this.$emit('validStateChanged', this.valid)
    },
    editVenueImage() {
      if (!this.disabled) {
        this.setGlobalAction({
          type: 'fileUpload',
          action: {
            fileTypesAccept: 'image/*',
            useGoogle: true,
            target: { image: this.localVenue.white_label },
            save: value => {
              this.onSingleChange({ type: 'white_label', value })
            }
          }
        })
      }
    },
    removeVenueImage() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          message: this.translations.confirmRemoveImages,
          title: this.translations.confirmRemoveTitle,
          callback: () => {
            this.onSingleChange({ type: 'white_label_clear' })
          },
        })
      }
    },
    paymentInfoChanged(operations) {
      if (this.user.god_mode) {
        if (this.venue.id) {
          this.$emit('singleOperation', operations)
        } else {
          this.paymentOperations = operations
        }
      }
    },
    venueFinderChanged(operations) {
      if (this.venue.id) {
        this.$emit('singleOperation', operations)
      } else {
        this.venueFindOperations = operations
      }
    },
    onDraftInvoiceSettingChange(draftInvoiceSettingType) {
      let enabled = draftInvoiceSettingType !== 'none',
        type = enabled ? draftInvoiceSettingType : undefined,
        value = { enabled, type },
        operation = { type: 'draft_invoices_settings', value }

      if (this.localVenue.id) {
        this.onSingleChange(operation)
      } else if (this.$refs.inputGroup?.setOperation) {
        this.$refs.inputGroup.setOperation({ operation, allowDuplicateOperationType: false, operationTypes: { set: 'draft_invoices_settings' } })
      }
    },
    onSingleChange(operation) {
      if (this.venue.id) {
        this.$emit('singleOperation', operation)
      }
    },
    onUseParentItemsChange() {
      setTimeout(() => {
        if (!this.localVenue.use_parent_items) {
          this.localOperations = this.localOperations.filter(o => o.type !== 'use_parent_pos_items' && o.type !== 'use_parent_items')
        }
      }, 0)
    },
    onPopulateMethodChange() {
      if (!this.populateMethod) {
        this.localVenue.copy_data_from_venue = false
        this.localVenue.use_parent_items = false
        this.localVenue.use_parent_pos_items = false
      }

      if (this.populateMethod === 2) {
        this.localVenue.use_parent_items = true
      }
    },
    onChange(operations) {
      if (this.populateMethod !== 1) {
        this.localOperations = operations.filter(o => o.type !== 'copy_data_from_venue')
      } else if (this.populateMethod !== 2) {
        this.localOperations = operations.filter(o => o.type !== 'use_parent_pos_items' && o.type !== 'use_parent_items')
      }
    },
    mergeOperations() {
      this.operations = [...this.localOperations, ...this.venueFindOperations, ...this.paymentOperations]
    },
    goToQuickbooks() {
      let tabOpen = window.open('about:blank', 'newtab')

      api.quickbooksAuth().then(result => {
        if (result && result.url) {
          tabOpen.location = result.url
        }
      })
    },
    resyncVenueData() {
      if (this.$refs.confirmDialogResyncVenueData) {
        this.$refs.confirmDialogResyncVenueData.confirm({
          message: 'Are you sure you want to resync venue data? This process might take a while.',
          title: 'Confirm Resync',
          callback: () => {
            api.couchBaseResync(this.venue.id, this.resyncVenueDataIncreaseVersion).then(() => {
              this.notify({
                message: 'Resyncing venue data. This may take a few minutes.',
                type: 'info'
              })
            })
          },
        })
      }
    },
    resyncAreasData() {
      api.resyncAreas().then(() => {
        this.notify({
          message: 'Resyncing areas data. This may take a few minutes.',
          type: 'info'
        })
      })
    },
    resetLocalData() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          message: this.translations.confirmResyncVenueData,
          title: this.translations.confirmResyncVenueDataTitle,
          callback: () => {
            this.resetSyncProviderLocalPersistence()
          },
        })
      }
    },
    sendVenueReport(type) {
      if (type === 'weekly') {
        api.sendVenueWeeklyReport().then(() => {
          this.notify({
            message: 'Venue weekly report will be sent to your email address.',
            type: 'success'
          })
        })
      } else {
        api.sendVenueDailyReport().then(() => {
          this.notify({
            message: 'Venue daily report will be sent to your email address.',
            type: 'success'
          })
        })
      }
    }
  },
  created() {
    if (this.user?.god_mode) {
      this.loadingOrganizations = true
      api.organizations().then(data => {
        this.organizations = data
      }).finally(() => {
        this.loadingOrganizations = false
      })
    }
  },
  watch: {
    populateMethod: 'onPopulateMethodChange',
    operations(operations) {
      if (!this.venue.id) {
        this.$emit('input', operations)
      }
    },
    venue: {
      handler() {
        this.localVenue = merge({ subscription: {} }, this.venue, {
          pos_integration_status: get(this.venue, 'pos.status.type', 'no'),
          draft_invoices_settings: merge({}, { enabled: false, type: 'none' }, this.venue.draft_invoices_settings)
        })

        if (this.localVenue.payment) {
          this.payment = { ...this.localVenue.payment }
        }
        this.initDone = true
      },
      immediate: true
    },
    localOperations(operations) {
      if (Array.isArray(operations)) {
        this.mergeOperations()
      }
    },
    venueFindOperations(operations) {
      if (Array.isArray(operations)) {
        this.mergeOperations()
      }
    },
    paymentOperations(operations) {
      if (Array.isArray(operations)) {
        this.mergeOperations()
      }
    }
  }
}
</script>

<style lang="scss">
.venue-settings-col {
  // min-width: 200px;
  min-height: 55px;
}
</style>
