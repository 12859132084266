<template>
  <wiskModal :size="size" :title="modalTitle" @ok="ok" :centered="centered" v-model="modalOpen" v-bind="$attrs" class="wisk-confirm" @hide="cancel" :okDisabled="okDisabled"
    style="cursor: default;" :hideOK="hideOK" hideHeaderExtra :okText="okText" :cancelText="cancelText" :defaultZIndex="zIndex">

    <p style="white-space: pre-line;" v-if="modalMessage" class="mt-0 pt-0">
      {{ modalMessage }}
    </p>

    <slot></slot>

    <wiskInputGroup @errorCountChanged="setValidState">
      <wiskInput v-if="promptForText" ref="textInput" :infoTooltipKey="promptForTextInfoTooltipKey" :inputType="promptForTextType === 'password' ? 'text' : promptForTextType" :multiline="textarea" v-model="text"
        :passwordField="promptForTextType === 'password'" :validations="validations" :required="textRequired" @enter="ok" :label="promptForTextLabel || label" :suffix="promptForTextSuffix"
        :helperText="promptForTextHelpText" v-bind="inputParams" />
    </wiskInputGroup>


    <template v-slot:modal-footer-left-side>
      <slot name="modal-footer-left-side"></slot>
    </template>

  </wiskModal>
</template>

<script>
import { mapState } from 'vuex'
import wiskModal from '@/components/common/WiskModal'

export default {
  name: 'WiskConfirmDialog',
  emits: ['ok', 'cancel', 'hide'],
  components: { wiskModal },
  props: {
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    textarea: Boolean,
    size: { type: String, default: 'lg' },
    promptForTextType: { type: String, default: 'text' },
    promptForTextSuffix: { type: String, default: '' },
    promptForTextLabel: { type: String, default: '' },
    promptForTextHelpText: { type: String, default: '' },
    promptForTextInfoTooltipKey: String,
    autofocus: Boolean,
    validations: {
      type: Array,
      default: () => []
    },
    inputParams: { type: Object },
    zIndex: Number,
    centered: { type: Boolean, default: true }
  },
  data() {
    return {
      modalTitle: '',
      modalMessage: '',
      label: '',
      modalOpen: false,
      callback: null,
      cancelCallback: null,
      text: '',
      hideOK: false,
      validationMessage: '',
      promptImputValid: null,
      promptForText: false,
      textRequired: false,
      okText: '',
      cancelText: '',
      callbackExecuted: false,
      modalFooerLeftSide: false
    }
  },
  computed: {
    ...mapState(['translations']),
    okDisabled() {
      return (this.promptForText && !this.promptImputValid) || this.callbackExecuted
    }
  },
  methods: {
    setValidState(errorCount) {
      this.promptImputValid = !errorCount
    },
    confirm({ callback, message, title, promptForText = false, autoConfirm = false, cancelCallback = null, hideOK = false, okText = '', cancelText = '', defaultPromptForTextValue = '' }) {
      this.modalTitle = title || this.modalTitle
      this.modalMessage = message || this.modalMessage
      this.promptForText = promptForText
      this.hideOK = hideOK
      this.okText = okText
      this.cancelText = cancelText
      this.text = defaultPromptForTextValue

      if (typeof cancelCallback === 'function') {
        this.cancelCallback = cancelCallback
      }
      if (typeof callback === 'function') {
        this.modalOpen = !autoConfirm
        this.callback = callback
        if (autoConfirm) {
          this.callbackExecuted = false
          this.ok()
        }
      } else if (typeof cancelCallback === 'function') {
        this.modalOpen = true
      } else {
        console.error('WiskConfirmDialog was called without a callback or cancelCallback method')
      }

      if (this.autofocus && this.promptForText) {
        setTimeout(() => {
          let input = this.$refs.textInput
          input && input.focus()
        }, 100)
      }
    },
    prompt({ callback, message, title, required, text = '', label = '' }) {
      this.text = text.trim()
      this.label = label
      this.textRequired = !!required
      this.confirm({ callback, message, title, promptForText: true, defaultPromptForTextValue: this.text })
    },
    ok() {
      if (!this.okDisabled) {
        this.callback && this.callback(this.promptForText ? this.text : undefined)
        this.callbackExecuted = true
        this.$emit('ok')
        this.cancelCallback = null
        this.modalOpen = false
      }
    },
    cancel() {
      this.modalOpen = false
      this.$emit('cancel')
      this.$emit('hide')
      this.cancelCallback && this.cancelCallback()
    }
  },
  mounted() {
    this.modalTitle = this.title || this.translations.confirmItemVariantSetDefaultTitle
    this.modalMessage = this.message
  },
  watch: {
    modalOpen() {
      this.callbackExecuted = false
    }
  }
}
</script>

<style lang="scss">
.wisk-confirm {
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
