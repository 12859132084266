<template>
  <component :is="editAction ? 'wiskModal' : 'div'" :visible="!!editAction" @hide="setGlobalAction({ type: 'itemVariantEdit', action: null })" hideHeaderExtra
    :hideFooter="!!itemVariant?.id" :title="editAction ? modalTitle : ''" :warning="!!itemVariant?.archived">

    <small class="text-danger text-uppercase text-bold mb-2 d-block text-center" v-if="localItemVariant?.archived"> {{ translations.txtGenericArchived }} </small>

    <h5 v-if="itemComputed?.is_measurement_locked" class=" pb-1 my-3 text-danger">{{ translations.txtBottlesMeasurementLockedInfo }}</h5>

    <wiskInputGroup @errorCountChanged="setValidState" :disabled="disabled" v-if="localItemVariant" style="max-width: 500px; border-radius: 5px;"
      :style="{ 'min-height': editAction ? '570px' : null, padding: inlineInsideParent ? 0 : null }" class="py-2" :operationTriggerButton="operationTriggerButton"
      @operationsChange="onChange" ref="localInputGroup" :class="{ 'bg-warning': localItemVariant.archived }" @value="$emit('value', $event)">

      <wiskInput infoTooltipKey="7be0961a-2ddf-4944-b44e-9d265ddc1ae6" :label="translations.txtVenueBottlesVariantLabel" :modelValue="localItemVariant.title" operation="title" @operation="onSingleFieldChange"
        :triggerInputOnLoad="!localItemVariant.id && localForceInitOperations" :validations="[validations.uniqueVariant]" />

      <wiskInput infoTooltipKey="e088cfe0-6009-4ef9-a345-34762084ba8d" label="should be hidden" style="display: none;" inputType="number" :modelValue="itemIdComputed" required dummy class="d-none"
        @operation="onSingleFieldChange" operation="item_id" triggerInputOnSet :triggerInputOnLoad="localForceInitOperations" v-if="!emitOperationNoSave" />

      <wiskSelect infoTooltipKey="9a5becda-4cd9-414b-89d8-44092971738c" :label="translations.txtGenericDistributor" v-show="activeDistributors?.length" :modelValue="localItemVariant.distributor"
        operation="distributor_id" operationEmpty="distributor_clear" :items="activeDistributors" valueName="distributor" @operation="onSingleFieldChange" :triggerInputOnLoad="!localItemVariant.id && localForceInitOperations"
        :addNewItem="addDistributorConfig" />

      <div v-if="itemComputed && localItemVariant && itemComputed.item_distributor_ids && itemComputed.item_distributor_ids.length > 1 && !localItemVariant.archived" class="mb-3">
        <wiskInput :label="localItemVariant.is_default ? translations.txtVenueBottlesVariantDefault : translations.txtVenueBottlesVariantDefaultSet" :modelValue="localItemVariant.is_default" :triggerInputOnLoad="localForceInitOperations"
          @operation="setAsDefault" inputType="checkbox" :disabled="localItemVariant.is_default || itemComputed?.is_measurement_locked" operation="set_default" ref="asDefaultCheckbox" v-if="localItemVariant.id"
          :inputClass="localItemVariant.is_default ? 'text-bold' : ''" infoTooltipKey="variantDefaultInfoTooltip" />
      </div>

      <wiskInput infoTooltipKey="e6b67430-d627-4ca8-beb4-e15e3c7dac06" :label="translations.txtGenericCode" :modelValue="localItemVariant.item_code" operation="item_code" :triggerInputOnLoad="localForceInitOperations"
        operationEmpty="item_code_clear" @operation="onSingleFieldChange" />

      <wiskInput infoTooltipKey="c645dd57-8a03-4e02-baec-4293b5eb792c" :label="translations.txtVenueBottlesVariantReceiveOnly" :modelValue="localItemVariant.receive_only" operation="receive_only" :triggerInputOnLoad="localForceInitOperations"
        @operation="onSingleFieldChange" inputType="checkbox" />

      <wiskInput infoTooltipKey="264abe2f-d767-40c7-a191-19ff3a5a8d18" :label="translations.txtCartBuilderOrderGuideItem" :modelValue="orderGuide" operation="order_guide" :triggerInputOnLoad="localForceInitOperations"
        @operation="onSingleFieldChange" inputType="checkbox" />

      <priceEditor v-if="itemComputed" :itemVariantId="localItemVariant.id" :itemId="itemIdComputed" v-model="priceInfo.price" operation="price_per_uom" @operation="onSingleFieldChange"
        :disabled="priceEditingDisabled">
        <b-button variant="link" class="float-end h-100 middle-center" size="sm" v-if="itemComputed?.linked_subrecipe_id" v-tooltip="translations.txtVenueBottlesPriceDisabledSubrecipe"
          @click="setGlobalAction({ type: 'subrecipeEdit', action: { id: itemComputed.linked_subrecipe_id }, preventMultiple: true })">
          <icon name="wisk-information-button" scale="1"></icon>&nbsp;
        </b-button>
        <itemCostUnitInfo v-if="!!get(priceInfo, 'intakes.length')" style="margin-right: 15px;" :params="{ ...itemComputed, price: priceInfo }" :showDiscountInfoTooltip="false" />
      </priceEditor>

      <priceEditor :disabled="disabled" v-show="!!itemIdComputed" :itemVariantId="localItemVariant.id" :itemId="itemIdComputed" v-model="localItemVariant.alert_cost_per_uom" operation="alert_cost_per_uom" @operation="onSingleFieldChange"
        :title="translations.txtPOSItemsCostAlert" :label="translations.txtPOSItemsCostAlert" v-if="itemComputed" />

      <wiskInputGroup v-if="itemComputed && venue.show_current_distributor_prices" key="current_distributor_price">
        <priceEditor :disabled="disabled" v-show="!!itemIdComputed" :itemVariantId="localItemVariant.id" :itemId="itemIdComputed" :modelValue="localItemVariant?.current_distributor_price?.price" operation="price"
          @operation="onCurrentDistributorPriceChanged" :title="translations.txtBottlesVariantCurrentDistributorPrice" :label="translations.txtBottlesVariantCurrentDistributorPrice" />
        <p v-if="localItemVariant?.current_distributor_price?.expiration_date" class="small" style="margin-top: -12px;">
          <span v-if="!validations.expiration_date.validator(localItemVariant?.current_distributor_price?.expiration_date)" class="text-bold text-red mr-1" :key="localItemVariant?.current_distributor_price?.expiration_date">
            {{ validations.expiration_date.message }}
          </span>
          (Exp: {{ formatDate(localItemVariant?.current_distributor_price?.expiration_date) }})
        </p>
        <confirm ref="currentDistributorPriceExpirationDatePrompt" promptForTextInfoTooltipKey="b276c06d-2bdb-43e3-961a-964445212eaf" promptForTextType="datepicker" :inputParams="{ min: new Date() }"
          :promptForTextLabel="translations.txtBottlesVariantCurrentDistributorPriceExpirationDate" :validations="[validations.expiration_date]" />
      </wiskInputGroup>
      <wiskInput infoTooltipKey="99c5e091-077d-445f-a1c6-e8b4093f886f" :label="translations.txtGenericFee" :modelValue="localItemVariant.fee"
        inputType="number" :decimals="4" decimalsAsNeeded :minDecimals="2" operation="fee" v-if="venue.price_fee" @operation="onSingleFieldChange" />

      <itemMeasurement :triggerInputOnLoad="!localItemVariant.id && localForceInitOperations" :inline="!localItemVariant.id" :itemVariant="localItemVariant" :disabled="itemComputed?.is_measurement_locked"
        :label="translations.txtVenueBottlesMeasurement" v-model:measurement="localItemVariant.measurement" v-model:conversion="localItemVariant.measurement_conversion"
        @operations="onMeasurementChange">

        <template v-slot:edit-button v-if="itemComputed?.linked_subrecipe_id">
          <b-button variant="link" class="info-icon text-primary" v-tooltip="translations.txtSubrecipesLinkedSubrecipe"
            @click="setGlobalAction({ type: 'subrecipeEdit', action: { id: itemComputed?.linked_subrecipe_id } })">
            <icon name="wisk-arrow-right-circle" />
          </b-button>
        </template>
      </itemMeasurement>

      <wiskInput infoTooltipKey="6f0a1843-ac11-4205-870c-6f4f48d374fc" :label="translations.txtVenueBottlesVariantInventoriableAsUnits" :modelValue="localItemVariant.inventoriable" operation="inventoriable"
        @operation="onSingleFieldChange" inputType="checkbox" v-if="itemComputed && itemComputed.item_distributor_ids" :triggerInputOnLoad="localForceInitOperations" />

      <wiskInput infoTooltipKey="b5a6c416-9a16-48d4-9c20-6281b802c62a" :label="translations.txtGenericCaseSize" :modelValue="localItemVariant.case_size" inputType="number" operation="case_size"
        @operation="onSingleFieldChange" :operationConfirm="operationConfirmCaseSize" :triggerInputOnLoad="!localItemVariant.id && localForceInitOperations" :disabled="itemComputed?.is_measurement_locked"/>

      <wiskInput infoTooltipKey="2a63fec0-5bc1-4fb9-8fa7-166c1bf8614c" :label="translations.txtVenueBottlesVariantInventoriableAsCases" :modelValue="localItemVariant.inventoriable_as_cases"
        operation="inventoriable_as_cases" :triggerInputOnLoad="localForceInitOperations" @operation="onSingleFieldChange" inputType="checkbox" v-if="localItemVariant.case_size > 1" />

      <wiskSelect infoTooltipKey="749616e8-4b37-4546-a20d-42648a4a401f" :label="translations.txtGenericOrderFormat" v-if="localItemVariant.case_size > 1 && orderFormats && orderFormats.length" :items="orderFormats"
        :modelValue="localItemVariant.order_format" @operation="onSingleFieldChange" required operation="order_format" />

      <wiskSelect infoTooltipKey="5ac7b970-6119-4dd9-9399-9e7555142a25" :label="translations.txtGenericWeights" :modelValue="localItemVariant.weights" operation="weight_add" operationEmpty="weight_delete"
        taggable multiple forceNumber :multiselectOptions="multiselectOptionsWeights" valueName="weights" @operation="onSingleFieldChange" />

      <wiskSelect infoTooltipKey="7018ddd9-6d3a-47f2-84fb-c4d474a0d939" :label="translations.txtGenericEmptyWeights" :modelValue="localItemVariant.empty_weights" :multiselectOptions="multiselectOptionsEmptyWeights"
        operationEmpty="empty_weight_delete" valueName="empty_weights" taggable multiple forceNumber @operation="onSingleFieldChange" operation="empty_weight_add" />

      <wiskSelect infoTooltipKey="f300f3f4-eacb-4bf3-abb5-a0decd934559" :label="translations.txtGenericBarcodes" :modelValue="localItemVariant.barcodes" @operation="onSingleFieldChange" operation="barcode_add"
        operationEmpty="barcode_delete" :multiselectOptions="multiselectOptionsBarcodes" taggable multiple valueName="barcodes" :tagValidator="value => value.length > 5" />

      <wiskSelect infoTooltipKey="8fc0dddb-d670-45b9-a8a4-c0530b296397" :items="activeGLAccounts" @operation="onSingleFieldChange" :modelValue="localItemVariant.gl_account_id" operationEmpty="gl_account_id_clear"
        operation="gl_account_id" :label="translations.txtGLAccount" :addNewItem="addGLAccountConfig" v-if="currentPermissionsByType.gl_account_assign && venue.accounting_enabled" />

      <wiskSelect infoTooltipKey="821d7181-f929-4e1d-8428-cc4a6b1b955c" :items="activeTaxes" @operation="onSingleFieldChange" :modelValue="localItemVariant.tax_rate_id" operation="tax_rate_id" :label="translations.txtTax"
        :addNewItem="addTaxConfig" operationEmpty="tax_rate_id_clear" class="tax-selector" v-if="currentPermissionsByType.tax_rate_assign && venue.taxes_enabled" />
    </wiskInputGroup>

    <template v-slot:modal-footer>
      <editorButtons :editorValid="valid" :save="saveNew" />
    </template>

    <confirm ref="confirmDialog" />
    <confirm ref="confirmDialogMeasurement" />

  </component>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import merge from 'lodash.merge'
import get from 'lodash.get'
import { DateTime } from 'luxon'
import { getBaseValueFromMeasurement, getMeasurementDisplayString, formatDate } from '@/modules/utils'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import itemMeasurement from '@/components/bottles/ItemMeasurement'
import priceEditor from '@/components/bottles/ItemPrice'
import infoTooltip from '@/components/common/WiskInfoTooltip'
import itemCostUnitInfo from '@/components/bottles/ItemCostUnitInfo'

export default {
  name: 'ItemVariantEdit',
  emits: ['variantIsDefault', 'operation', 'switchToVariant', 'newVariant', 'value'],
  components: { editorButtons, priceEditor, infoTooltip, itemCostUnitInfo, itemMeasurement },
  props: {
    editAction: Object,
    disabled: Boolean,
    itemId: Number,
    itemVariant: Object,
    item: Object,
    distributor: Object,
    emitOperationNoSave: Boolean,
    distributorCode: String,
    usePriceFromVariant: Boolean,
    operationTriggerButton: Boolean,
    triggerInputOnLoad: { type: Boolean, default: true }
  },
  data() {
    return {
      localItemVariant: null,
      orderGuide: false,
      valid: true,
      operations: [],
      localItemId: null,
      localItem: null,
      itemEditorParentComponent: null,
      priceInfo: { price: {} },
      localForceInitOperations: false,
      get
    }
  },
  computed: {
    ...mapState(['translations', 'bottlesById', 'distributorsById', 'itemVariantsById', 'currentPermissionsByType', 'orderGuideVariantIdsMap', 'itemVariantPricesById']),
    ...mapGetters(['orderFormats', 'activeDistributors', 'venue', 'activeGLAccounts', 'activeTaxes', 'currency']),
    validations() {
      return {
        uniqueVariant: { type: 'uniqueVariant', validator: value => !this.variants.some(item => item && item.distributor_id !== this.localItemVariant?.distributor_id && item.title === value), message: this.translations.txtVenueBottlesVariantNameNotUnique },
        expiration_date: { type: 'expiration_date', validator: value => (value && (DateTime.now() < DateTime.fromISO(value) || DateTime.now() < DateTime.fromJSDate(value))), message: this.translations.txtBottlesVariantCurrentDistributorPriceExpired }
      }
    },
    operationConfirmCaseSize() {
      return {
        message: this.translations.txtItemOperationsCaseSizeChangeMessage,
        okText: this.translations.txtItemOperationsCaseSizeChangeOK,
        cancelText: this.translations.txtItemOperationsCaseSizeChangeCancel,
        autoConfirm: this.localItemVariant?.id === 0,
        cancelCallback: (value) => {
          this.$refs.confirmDialog.confirm({
            callback: () => {
              this.setGlobalAction({
                type: 'itemVariantEdit',
                action: {
                  id: 0,
                  itemId: this.itemComputed.item_id,
                  itemVariant: {
                    id: 0,
                    case_size: value
                  },
                  onChange: itemVariant => {
                    console.log('itemVariant', itemVariant)
                  }
                }
              })
            },
            message: this.translations.txtItemOperationsCaseSizeCreateVariantConfirm,
            title: this.translations.confirmItemsGridFloatingActionTitle
          })
        }
      }
    },
    addGLAccountConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'glAccountEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: glAccount => {
                callbackItemInjected(glAccount)
              }
            }
          })
        },
        label: this.translations.txtGLAccountNew
      }
    },
    addTaxConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'taxEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: tax => {
                callbackItemInjected(tax)
              }
            }
          })
        },
        label: this.translations.txtTaxesNew
      }
    },
    modalTitle() {
      if (this.itemComputed) {
        let item = this.itemComputed
        return this.translations.translate('tplVenueBottlesVariantFor', { '{a}': item.title })
      }
      return ''
    },
    priceEditingDisabled() {
      return this.disabled || !!(this.itemComputed && this.itemComputed.linked_subrecipe_id) || !!get(this.priceInfo, 'intakes.length')
    },
    inlineInsideParent() {
      return !this.itemComputed || (this.itemComputed && this.itemComputed.item_distributor_ids && this.itemComputed.item_distributor_ids.length < 2)
    },
    itemIdComputed() {
      return this.itemId || this.localItemId || (this.editAction && this.editAction.itemId) || 0
    },
    itemComputed() {
      if (this.localItem) {
        return this.localItem
      }
      if (this.item) {
        return this.item
      }
      if (this.itemIdComputed) {
        return this.bottlesById[this.itemIdComputed]
      }
      return null
    },
    addDistributorConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'distributorEdit',
            action: {
              id: 0,
              title: searchQuery,
              onChange: distributor => {
                callbackItemInjected(distributor)
              }
            }
          })
        },
        label: this.translations.txtGenericNewDistributor
      }
    },
    multiselectOptionsBarcodes() {
      return {
        tagPlaceholder: this.translations.txtGenericAddBarcode,
        placeholder: this.translations.txtGenericInputBarcode
      }
    },
    multiselectOptionsWeights() {
      return {
        tagPlaceholder: this.translations.txtGenericAddWeight,
        placeholder: this.translations.txtGenericInputWeight
      }
    },
    multiselectOptionsEmptyWeights() {
      return {
        tagPlaceholder: this.translations.txtGenericAddEmptyWeight,
        placeholder: this.translations.txtGenericInputEmptyWeight
      }
    },
    newItemVariant() {
      return {
        id: 0,
        price: 0,
        title: '',
        item_code: '',
        item_id: this.itemIdComputed,
        barcodes: [],
        empty_weights: [],
        weights: [],
        case_size: 1,
        order_format: 'unit',
        price_per_uom: { value: 0, type: 'unit' },
        measurement: { quantity: 1, unit_of_measurement: 'unit' }
      }
    },
    variants() {
      if (this.itemComputed) {
        return this.itemComputed.variants || (this.itemComputed.item_distributor_ids || []).map(id => id && this.itemVariantsById[id])
      }
      return []
    }
  },
  methods: {
    formatDate,
    ...mapActions(['setGlobalAction', 'setItemVariant']),
    onCurrentDistributorPriceChanged(operation) {
      if (this.$refs.currentDistributorPriceExpirationDatePrompt) {
        this.$refs.currentDistributorPriceExpirationDatePrompt.confirm({
          title: this.translations.txtBottlesVariantCurrentDistributorPriceExpirationDate,
          promptForText: true,
          required: true,
          defaultPromptForTextValue: this.localItemVariant?.current_distributor_price?.expiration_date,
          callback: (payload) => {
            this.onSingleFieldChange({
              type: 'current_distributor_price',
              value: {
                price: operation.value,
                expiration_date: payload
              }
            })
          }
        })
      }
    },
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    setAsDefault() {
      if (this.localItemVariant && this.itemComputed && this.$refs.confirmDialog) {
        let found = this.variants.find(v => v && v.is_default),
          autoConfirm =
            !!found &&
            this.localItemVariant.measurement.type === this.itemComputed.measurement.type &&
            getBaseValueFromMeasurement(found.measurement) === getBaseValueFromMeasurement(this.localItemVariant.measurement)

        this.$refs.confirmDialog.confirm({
          callback: () => {
            if (this.emitOperationNoSave) {
              this.$emit('variantIsDefault', this.localItemVariant.id)
            } else {
              this.save({ type: 'set_default', value: true })
            }
          },
          cancelCallback: () => {
            const checkbox = this.$refs.asDefaultCheckbox.getInputComponentRef()
            checkbox.setValue(false)
          },
          autoConfirm,
          message: this.translations.confirmItemVariantSetDefaultText,
          title: this.translations.confirmItemVariantSetDefaultTitle
        })
      }
    },
    onSingleFieldChange(operation) {
      if (this.localItemVariant.id) {
        this.save(operation)
      }
    },
    onMeasurementChange(operations = []) {
      if (operations?.length && this.localItemVariant?.id && this.$refs.confirmDialogMeasurement) {
        let measurementOperation = operations.find(o => o.type === 'measurement'),
          autoConfirm = (measurementOperation && measurementOperation?.from?.unit_of_measurement === measurementOperation?.value?.unit_of_measurement && measurementOperation?.from?.quantity === measurementOperation?.value?.quantity)
            || !this.itemIdComputed
            || (this.localItemVariant && !this.localItemVariant.id),
          baseValueFrom = getBaseValueFromMeasurement(measurementOperation?.from),
          baseValueTo = getBaseValueFromMeasurement(measurementOperation?.value),
          message = this.translations.translate('tplConfirmHistoryChangingOperation', { '{a}': this.translations.txtGenericMeasurement.toLowerCase() }),
          isUnitOrEach = measurementOperation?.value?.unit_of_measurement === 'unit' || measurementOperation?.value?.unit_of_measurement === 'ea',
          okText = '',
          measurementDisplayString = getMeasurementDisplayString(measurementOperation?.value)

        if (baseValueFrom && baseValueTo && (baseValueTo > baseValueFrom * 1.1 || baseValueTo < baseValueFrom * 0.9)) {
          message = this.translations.translate('tplConfirmItemMeasurementVariationBy10Percent', { '{a}': getMeasurementDisplayString(measurementOperation?.from), '{b}': measurementDisplayString })
          autoConfirm = false
        }

        if (baseValueTo > 100000) {
          message = this.translations.translate('tplConfirmItemMeasurementGreaterThanKgOrLThreshold', { '{a}': measurementDisplayString })
          autoConfirm = false
        }

        if (isUnitOrEach) {
          message = this.translations.translate('tplConfirmItemMeasurementUnit', { '{a}': measurementDisplayString })
          autoConfirm = false
          okText = this.translations.txtGenericConfirm + ' ' + measurementDisplayString
        }

        this.$refs.confirmDialogMeasurement.confirm({
          callback: () => {
            this.save(operations)
          },
          cancelCallback: () => {
            this.localItemVariant.measurement = measurementOperation?.from
          },
          message,
          okText,
          title: this.translations.confirmItemsGridFloatingActionTitle,
          autoConfirm // we allow change of alias without confirmation
        })
      }
    },
    onChange(operations) {
      this.operations = operations
    },
    saveNew() {
      let operations = this.operations

      if (operations?.length && !this.localItemVariant?.id && this.$refs.confirmDialogMeasurement) {
        let measurementOperation = operations.find(o => o.type === 'measurement'),
          autoConfirm = true,
          baseValueTo = getBaseValueFromMeasurement(measurementOperation.value),
          message = ''

        if (baseValueTo > 100000) {
          message = this.translations.translate('tplConfirmItemMeasurementGreaterThanKgOrLThreshold', { '{a}': getMeasurementDisplayString(measurementOperation.value) })
          autoConfirm = false
        }

        this.$refs.confirmDialogMeasurement.confirm({
          callback: () => {
            this.save(operations)
          },
          message,
          title: this.translations.confirmItemsGridFloatingActionTitle,
          autoConfirm
        })
      }
    },
    save(operation = this.operations) {
      if (this.emitOperationNoSave) {
        this.$emit('operation', this.operations)
      } else {
        this.setItemVariant({ id: this.localItemVariant.id, operation })
          .then(result => {
            if (this.editAction?.onChange) {
              this.editAction.onChange(result)
            }
            if (this.editAction?.id === 0) {
              this.setGlobalAction({ type: 'itemVariantEdit', action: null })
            }
          })
          .catch(error => {
            this.localItemVariant = merge({}, this.newItemVariant, this.itemVariant)

            let failed = get(error, 'data.info')
            if (failed && Array.isArray(failed.used_by)) {
              this.setGlobalAction({
                type: 'itemOperationErrorHandler',
                action: {
                  items: [failed],
                  operation,
                  retry: o => {
                    this.save(o)
                  }
                }
              })
            }
          })
      }

    }
  },
  watch: {
    orderGuideVariantIdsMap: {
      immediate: true,
      handler(orderGuideVariantIdsMap) {
        if (orderGuideVariantIdsMap && this.itemVariant?.id) {
          this.orderGuide = !!orderGuideVariantIdsMap[this.itemVariant.id]
        }
      }
    },
    itemVariant: {
      immediate: true,
      handler(itemVariant) {
        this.localItemVariant = merge({}, this.newItemVariant, itemVariant)
        this.localForceInitOperations = this.triggerInputOnLoad
        if (this.distributor) {
          this.localItemVariant.distributor = this.distributor
        }
        if (this.localItemVariant.distributor_id) {
          this.localItemVariant.distributor = this.distributorsById[this.localItemVariant.distributor_id]
        }
        if (this.distributorCode) {
          this.localItemVariant.item_code = this.distributorCode
        }
      }
    },
    itemVariantPricesById() {
      if (this.localItemVariant?.id) {
        this.priceInfo = this.itemVariantPricesById[this.localItemVariant.id] || { price: {} }
      }
    },
    localItemVariant: {
      handler() {
        if (this.usePriceFromVariant) {
          this.priceInfo = { price: this.localItemVariant.price_per_uom }
        } else if (this.localItemVariant && this.localItemVariant.id) {
          this.priceInfo = this.itemVariantPricesById[this.localItemVariant.id] || { price: {} }
        } else {
          this.priceInfo = { price: {} }
        }
      },
      immediate: true
    },
    item: {
      handler(item) {
        if (item) {
          this.localItemId = item.item_id
        }
      },
      immediate: true
    },
    editAction: {
      immediate: true,
      handler(editAction) {
        if (editAction && editAction.id === 0) {
          this.localItemVariant = merge({}, this.newItemVariant, editAction.itemVariant || {})

          if (editAction.localItem) {
            this.localItem = merge({}, editAction.localItem)
          }
          if (this.itemComputed && !get(editAction, 'itemVariant.measurement.unit_of_measurement')) {
            this.localItemVariant.measurement = merge({}, this.itemComputed.measurement)
          }
          if (this.localItemVariant.distributor_id) {
            this.localItemVariant.distributor = this.distributorsById[this.localItemVariant.distributor_id]
          }
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
